/* filter */

.filter {
  position: fixed;
  top: 45px;
  z-index: 20;
  width: calc(100% - 48px);
  background: var(--background-orange);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-bottom: 1px solid #0002;
  transition: max-height 0.3s;
  -webkit-transition: max-height 0.3s;

  &.closed {
    max-height: 40px;
    height: 40px;
  }

  &.open {
    max-height: 160px;
    height: auto;
  }

  .filterRow {
    width: 100%;
    height: 40px;
    display: flex;

    .inputHolder {
      width: 100%;
      padding: 11px;

      &.iconInputHolder {
        width: calc(100% - 24px);
      }
    }

    input,
    select {
      width: 100%;
    }

    .iconHolder {
      padding: 10px 12px 0 6px;

      .icon {
        width: 16px;
        stroke: #0008;
        margin: 3px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .filter {
    top: 65px;
    width: calc(100% - 80px);
    border-bottom: none;

    &.open,
    &.closed {
      max-height: 40px;
      height: 40px;
    }

    .filterRow {
      width: 20%;

      &.hiddenLg {
        display: none;
      }

      .inputHolder {

        &.iconInputHolder {
          width: 100%;
        }
      }

      input,
      select {
        width: 100%;
      }

      .iconHolder {
        display: none;
      }
    }
  }
}