.packHolder {
  width: 50%;
}

.pack {
  height: 60px;
  background: var(--light-orange);
  margin: 4px;
  border-radius: 6px;
  cursor: pointer;
}

.name {
  text-align: center;
  font-size: 18px;
  padding: 14px 0 0;
}

.cardCount {
  text-align: center;
  font-size: 14px;
  color: #0008;
}

.actionRow {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .packHolder {
    width: calc(100% / 5);
  }
}