.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
  min-height: 54px;
  max-height: 54px;
  height: 54px;
  border-bottom: 1px solid #0001;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.2s;

  &.open {
    height: auto;
    max-height: 200px;
  }

  .col {
    z-index: 2;
    position: relative;
    margin: 0;
    height: 54px;

    .cell {
      padding: 4px;
      width: 100%;
      height: 100%;

      input,
      select {
        user-select: none;
      }
    }
  }

  .backgroundHolder {
    display: none;
  }
}

// display: block;
// background: #daeedd;
// height: 100%;

@media only screen and (min-width: 768px) {
  .row {
    .backgroundHolder {
      display: block;
      width: calc(100% - 8px);
      position: absolute;
      z-index: 1;
      height: 54px;

      .background {
        background: var(--background-green);
        height: 100%;
      }
    }
  }
}