.sidebar {
  position: fixed;
  z-index: 98;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  pointer-events: none;
}

.sidebar a {
  text-decoration: none;
  color: unset;
}

.sidebarToggle {
  /* background: var(--dark-green); */
  width: 40px;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  pointer-events: all;
  background-image: linear-gradient(0deg, #33a854 0.63%, #23833d 0.63%, #23833d 50%, #33a854 50%, #33a854 50.63%, #23833d 50.63%, #23833d 100%);
  background-size: 158.00px 158.00px;
}

.sidebarPanel {
  background: var(--light-green);
  height: 100%;
  width: calc(100% - 40px);
  transform: translateX(0%);
  position: relative;
  z-index: 1;
  transition: 0.25s;
  -webkit-transition: 0.25s;
  pointer-events: all;
}

.sidebarPanel.closed {
  transform: translateX(-100%);
}

.headerLogo {
  margin: 20px;
}

/* sidebarItems */

.sidebarItems {
  color: #fff;
  font-size: 18px;
}

.sidebarFooter {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #0008;
}

/* sidebarItem */

.sidebarItem {
  padding: 20px 28px;
  cursor: pointer;
}

.sidebarItem.active {
  background: #FFF2;
}

.sidebarItem:hover {
  background: #FFF4;
}

.sidebarFooter .sidebarItem {
  padding: 8px 14px;
}

.sidebarFooter .sidebarItem.active {
  background: #0001;
}

.sidebarFooter .sidebarItem:hover {
  background: #0002;
}

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 232px;
  }
}