.form {
  width: 100%;
  max-width: 510px;

  .formRow {
    width: 100%;
    height: auto;
    min-height: 68px;
    display: flex;
    flex-wrap: wrap;

    .labelHolder {
      width: 100%;
      text-align: right;
      padding: 0 8px;

      .icon {
        stroke: #999;
        margin: 16px 8px;
        transform: scale(1.2);
      }

      .label {
        color: #999;
        padding: 6px 6px 0;
        font-size: 14px;
        text-align: left;
      }
    }

    .inputHolder {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      &.inputHolderFullWidth {
        width: 100%;
      }

      .formSliderHolder {
        width: 100%;
        padding: 8px 0 8px 12px;
      }

      .input {
        font-size: 14px;
        width: calc(100% - 16px);
        padding: 8px;
        border-bottom: 1px solid #0002;
        margin: 6px auto;
      }
    }

    .detail {
      width: 100%;
      display: flex;
      height: 24px;
      font-size: 12px;

      .message {
        width: calc(100% - 138px);
        margin-left: 38px;

        span.error {
          color: #f00;
        }
      }

      .link {
        width: 100px;
        font-size: 12px;
        color: #999;
        text-align: right;

        span {
          cursor: pointer;
        }

        span:hover {
          color: #888;
          text-decoration: underline;
        }
      }
    }

    .formMessage {
      color: #24833d;
      font-weight: bold;
    }

    .buttonHolder {
      position: relative;
      width: 100%;
      height: 0;
      margin: 0;

      div {
        float: right;
      }
    }

    &.iconRow {
      .labelHolder {
        width: 32px;
      }

      .inputHolder {
        width: calc(100% - 32px);
      }
    }

    &.error {
      .labelHolder {
        .icon {
          stroke: #F00;
        }

        .label {
          color: #F00;
        }
      }

      .inputHolder {
        .input {
          color: #f00;
          border-bottom: 2px solid #f00;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .form {
    .formRow {
      margin: 24px 0;

      .labelHolder {
        width: 130px;
        height: 50px;
        padding: 0;

        .label {
          padding: 18px 6px 6px;
          text-align: center;
        }
      }

      .inputHolder {
        width: calc(100% - 130px);
      }
    }
  }
}