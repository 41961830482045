.tableBody {
  margin: 40px 0 40px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .buttonHiddenLarge {
    display: none;
  }

  .tableBody {
    margin: 64px 0 40px;
  }
}