.button {

  // Default

  width: 184px;
  max-width: 184px;
  padding: 14px 0;
  display: flex;
  background: var(--dark-green);
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;
  -webkit-transition: background 0.2s;

  &:focus,
  &:hover {
    background: var(--light-green);
  }

  // Content

  .buttonIconHolder {
    width: 22px;
    margin: -4px 0;

    .buttonIcon {
      width: 22px;
      stroke: #FFF;
    }
  }

  .label {
    color: #FFF;
    width: 100%;
    text-align: center;
    margin: -1px 0;
    white-space: nowrap;
  }

  // Sizing

  &.form {
    width: calc(50% - 14px) !important;
    max-width: 152px;
    margin: 0 4px;
  }

  &.sm,
  &.small {
    padding: 4px 14px;
    border-radius: 4px;
    height: 22px;
    max-width: 78px;
    margin: 0 4px;

    .buttonIconHolder {
      width: 12px;

      .buttonIcon {
        width: 12px;
        margin: -6px -6px;
        transform: scale(1.4);
      }
    }

    .label {
      width: calc(100% - 12px);
    }
  }

  &.orange {
    background: var(--dark-orange);

    &:hover {
      background: var(--light-orange);
    }
  }

  // Color

  &.dark-green {
    background: var(--dark-green);
  }

  &.red {
    background: #d11e1e;
  }

  &.blue {
    background: #2e7fb6;
  }

  &.grey,
  &.gray {
    background: #999;
  }
}

@media only screen and (min-width: 768px) {
  .button {

    &.sm,
    &.small {
      padding: 8px 14px;
      height: 28px;
      max-width: 94px;
      font-size: 14px;
    }
  }
}