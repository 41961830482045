.display {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.title {
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  padding: 12px 20%;
}

.body {
  text-align: center;
  padding: 12px 15%;
}

.button {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}