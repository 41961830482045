.container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

/* Container Left */

.containerLeft {
  padding: 18px 18px 0;
  height: 100%;
  width: 100%;
  background: var(--background-orange);
  box-shadow: inset 0 0 100px #0001;
  -moz-box-shadow: inset 0 0 100px #0001;
  -webkit-box-shadow: inset 0 0 100px #0001;
  z-index: 2;
}

.containerLeft .logoHolder {
  width: 100%;
  display: inline-block;
}

.containerLeft .logoHolder .logo {
  margin-left: -6px;
}

.containerLeft .containerLeftBody {
  margin: 38px auto 0;
}

.containerLeft .containerLeftBody h1 {
  font-size: 20px;
  margin: 12px 0;
}

.containerLeft .containerLeftBody p {
  color: #0009;
  font-size: 14px;
  margin: 12px 0;
}

/* Container Right */

.containerRight {
  display: none;
  z-index: 2;
}

/* Canvas */

.canvas {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 768px) {
  .container {
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .containerLeft {
    padding: 48px 48px;
    height: 100%;
    width: 620px;
  }

  .containerLeftBody {
    margin: 0 !important;
    position: relative;
    top: calc(50% - 40px);
    transform: translateY(-50%);
    width: 360px;
  }

  .containerRight {
    display: inline;
    height: 100%;
    width: auto;
    position: relative;
    color: #fff;
    background: #0003;
    box-shadow: inset 0 0 100px #000a;
    -moz-box-shadow: inset 0 0 100px #000a;
    -webkit-box-shadow: inset 0 0 100px #000a;
  }

}

@media only screen and (min-width: 992px) {
  .containerLeft {
    padding: 48px 84px;
  }
}