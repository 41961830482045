:root {
  --light-green: #33a853;
  --dark-green: #23833d;
  --background-orange: #f5f2ee;
  --background-green: #daeedd;
  --light-orange: #e7b172;
  --dark-orange: #ce995c;
  --action-green: #bbeac2;
  --font-family: "Source Sans Pro", sans-serif;
  /* --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px; */
}


html,
body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: var(--font-family);
  background: var(--background-orange);
  overflow-x: hidden;
}

body {
  position: relative
}

input,
textarea,
select,
button {
  border: none;
  outline: none;
  background: none;
  font-family: "Source Sans Pro", sans-serif;
  resize: none;
  padding: 0;
}

@media only screen and (min-width: 768px) {

  input,
  textarea,
  select,
  button {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  }
}