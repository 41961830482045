.rowButton {
  border-bottom: none;

  .colButton {
    width: 100%;

    .button {
      margin: 7px auto;
    }
  }
}

.rowAddNew {
  display: none;
}

.rowPhrase {

  &.rowHeader {
    display: none;
  }

  .colToggleOpen {
    width: 38px;

    .toggle {
      width: 22px;
      height: 22px;
      margin: 16px 8px;
      background: var(--dark-green);
      border-radius: 11px;
      cursor: pointer;

      .arrow {
        position: absolute;
        height: 10px;
        width: 10px;
        margin-top: 5px;
        margin-left: 6px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
        background: none;
        transform: rotate(-45deg) scale(.8);
        transition: transform .1s ease, opacity .1s ease;
        transform-origin: center center;

        &.open {
          transform: rotate(135deg) scale(.8);
          margin-top: 7px;
        }
      }
    }
  }

  .colChinese {
    width: 50%;

    .cellChinese {
      font-size: 22px;
    }
  }

  .colProgressSmall {
    width: calc(50% - 54px);

    .progressBar {
      width: 85%;
      height: 10px;
      margin: 20px 5px;
      border-radius: 5px;
      float: right;
    }
  }

  .colPinyin {
    margin-left: 38px !important;
    height: 24px !important;
    width: calc(100% - 54px);
  }

  .colEnglish {
    margin-left: 38px !important;
    width: calc(100% - 54px);
    height: auto !important;

    textarea {
      min-height: 100%;

      &:placeholder-shown {
        padding-top: 18px;
      }
    }
  }

  .pack {}

  .colOptions {
    margin-left: 54px !important;
    width: calc(100% - 54px);
    display: flex;
    justify-content: flex-end;
    padding: 12px;
  }

  .colProgressLarge {
    display: none;
  }

  .cover {
    display: none;
  }
}



@media only screen and (min-width: 768px) {

  .rowAddNew {
    // background: var(--action-green);
    display: flex;

    // TODO: style this so that it's small and then expands when in use
  }

  .rowPhrase {
    // max-height: 78px;
    // height: 78px;
    border-bottom: none;

    .colToggleOpen {
      display: none;
    }

    .colProgressSmall {
      display: none;
    }

    .colChinese {
      width: 18%;
    }

    .colPinyin {
      margin: 0 !important;
      height: 100% !important;
      width: 18%;
    }

    .colPack {
      margin: 0 !important;
      width: 9%;
    }

    .colEnglish {
      margin: 0 !important;
      width: 27%;
    }

    .colOptions {
      width: 28%;
      margin: 0 !important;
      filter: opacity(0);
      transition: filter 0.2s;

      &.forceVisible {
        filter: opacity(1);
      }

      .buttonHideLarge {
        display: none;
      }
    }

    .colProgressLarge {
      width: 100%;
      display: block;
      position: absolute;
      margin-top: 40px;
    }

    .cover {
      display: flex;
      position: absolute;
      width: calc(100% - 10px);
      height: 54px;
      z-index: 3;
      pointer-events: none;

      .coverAutofill {
        margin-left: 18%;
        background: var(--action-green);
        border-radius: 4px;
        padding: 12px;
        height: calc(54px - 16px);
        width: calc(18% + 36%);
        font-size: 14px;
        color: #0009;
        margin-top: 6px;

        &.hidden {
          visibility: hidden;
        }

        // &.colPinyin {
        //   width: 18%;
        //   margin-left: 18% !important;
        // }

        // &.colEnglish {
        //   margin-left: calc(18% + 18%) !important;
        //   width: 36%;
        // }
      }

    }

    &:hover {
      .colOptions {
        filter: opacity(1);
      }
    }

    &.rowHeader {
      display: flex;
      position: fixed;
      top: 105px;
      z-index: 30;
      max-height: 28px;
      min-height: 28px;
      width: calc(100% - 80px);
      background: var(--background-orange);
      border-bottom: 1px solid #0003;

      .colHeader {
        height: 28px;
        padding: 8px 4px;
        font-size: 12px;
        color: #0009;
        cursor: pointer;

        &:hover {
          color: #000C;
        }
      }
    }
  }
}