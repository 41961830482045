.content {
  background: var(--background-orange);
  height: 100%;
  position: absolute;
  margin-left: 40px;
  width: calc(100% - 40px);
  top: 0;
  overflow: scroll;
}

.header {
  font-size: 16px;
  font-weight: bold;
  width: calc(100% - 40px);
  position: fixed;
  background: #f4f2ed;
  border-bottom: 1px solid #0003;
  z-index: 25;
  display: flex;

  .headerTitle {
    padding: 14px 0 14px 10px;
    width: 30%;
  }

  .headerActions {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding: 11px 8px;
  }
}

.pageBody {
  margin-top: 45px;
  padding: 0 4px;
  position: absolute;
  width: 100%;
}

@media only screen and (min-width: 768px) {

  .content {
    width: calc(100% - 40px);
    padding: 0 20px;
  }

  .header {
    padding: 6px 10px;
    font-size: 28px;
    width: calc(100% - 80px);
    font-size: 24px;
  }

  .pageBody {
    width: calc(100% - 40px);
    margin-top: 80px;
  }
}